import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { Link } from 'react-router-dom';
import FacebookIcon from '../assets/img/facebook-icon.png';
import InstagramIcon from '../assets/img/instagram-icon.png';
import MainArtTop from '../assets/img/main-art-top.png';
import MainArtTopMobile from '../assets/img/main-art-top-mobile.png';
import ArtLogo from '../assets/img/art-logo.png';
import ArtLogoMobile from '../assets/img/art-logo-mobile.png';
import Logo from '../assets/img/logo.png';
import '../assets/css/Home.css';

const INSTRAGRAM_URL = 'https://www.instagram.com/lovelyflowers.pt';
const FACEBOOK_URL = 'https://www.facebook.com/lovelyflowers.pt';

const Home = ({ windowResizeWidth, windowResizeHeight, modal, setModal, setView, mobile, setMobile }) => {
  const [loadArtLogo, setLoadArtLogo] = useState(false);
  const [loadArtTop, setLoadArtTop] = useState(false);

  const handleMobileModalView = () => {
    setModal(true);
    setView('modal');
  };

  useEffect(() => {
    document.title = 'Home | Lovely Flowers';
    if (!mobile) {
      document.body.style.overflow = 'hidden';
    }
  }, [mobile]);

  return (
    <React.Fragment>
      <section className={`main-container ${loadArtLogo && loadArtTop ? 'active' : ''}`}>
        <div className='logo-first-container'>
          {!mobile ? (
            <img onLoad={() => setLoadArtLogo(true)} src={ArtLogo} alt='Layout' />
          ) : (
            <img onLoad={() => setLoadArtLogo(true)} src={ArtLogoMobile} alt='Layout' />
          )}

          <div className='logo-container'>
            <img src={Logo} alt='Logo' />
          </div>
        </div>

        <div className='main-background-opacity'></div>

        <div className='home-social-container right-side-absolute'>
          <div className='vertical-text'>
            <p>as nossas páginas</p>
          </div>

          <div className='border-white'></div>

          <a href={`${FACEBOOK_URL}`} target='_blank' rel='noopener noreferrer'>
            <img src={FacebookIcon} alt='Facebook | Lovey Flowers' />
          </a>
          <a href={`${INSTRAGRAM_URL}`} target='_blank' rel='noopener noreferrer'>
            <img src={InstagramIcon} alt='Instagram | Lovey Flowers' />
          </a>
        </div>

        <h1>
          Brevemente
          <br />
          <span>
            A sua loja
            <br /> de flores
          </span>
        </h1>

        <div className='main-button' onClick={() => (!mobile ? setModal(true) : handleMobileModalView())}>
          <p>Encomendar</p>
        </div>

        <div className='social-container-mobile'>
          <a href={`${FACEBOOK_URL}`} target='_blank' rel='noopener noreferrer'>
            <img src={FacebookIcon} alt='Facebook | Lovey Flowers' />
          </a>
          <a href={`${INSTRAGRAM_URL}`} target='_blank' rel='noopener noreferrer'>
            <img src={InstagramIcon} alt='Instagram | Lovey Flowers' />
          </a>
        </div>
      </section>

      <section className={`main-art-top-container ${loadArtLogo && loadArtTop ? 'active' : ''}`}>
        {!mobile ? (
          <img onLoad={() => setLoadArtTop(true)} src={MainArtTop} alt='Layout' />
        ) : (
          <img onLoad={() => setLoadArtTop(true)} src={MainArtTopMobile} alt='Layout' />
        )}

        <div className='signature-container'>
          <p>
            <Link to='privacy-policy'>Política de Privacidade</Link>
          </p>
          <div className='signature-dots'>.</div>
          <p>Rua das Açucenas, 1300-003 Lisboa</p>
          <div className='signature-dots'>.</div>
          <p>+351 911 917 895</p>
          <div className='signature-dots'>.</div>
          <p>
            <a href='mailto:geral@lovelyflowers.pt'>geral@lovelyflowers.pt</a>
          </p>
        </div>
      </section>

      {!mobile ? <Modal modal={modal} setModal={setModal} /> : false}
    </React.Fragment>
  );
};

export default Home;
