import React from 'react';
import MainArtTop from '../assets/img/main-art-top.png';
import MainArtTopMobile from '../assets/img/main-art-top-mobile.png';
import '../assets/css/Banner.css';

const Banner = ({bannerImage, mobile}) => {

	return (

		<React.Fragment>

		<section className="banner-container" style={{backgroundImage: `url(${bannerImage})`}}>

			<div class="banner-background-opacity"></div>

		</section>

		<section className="banner-art-top-container">

			{!mobile 

			? 
				<img src={MainArtTop} alt="Layout"/>

			:

				<img src={MainArtTopMobile} alt="Layout"/>
			
			}

		</section>

		</React.Fragment>




	);

}

export default Banner;