export const formReducer = (state, action) => {

	switch (action.type) {
		case 'NAME':
			return {
				...state,
				name: {
					...state.name,
					value: action.payload
				}
			}
		case 'NAME_ERROR':
			return {
				...state,
				name: {
					...state.name,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}
		case 'FIRST_NAME':
			return {
				...state,
				firstName: {
					...state.firstName,
					value: action.payload
				}
			}
		case 'FIRST_NAME_ERROR':
			return {
				...state,
				firstName: {
					...state.firstName,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}
		case 'LAST_NAME':
			return {
				...state,
				lastName: {
					...state.lastName,
					value: action.payload
				}
			}
		case 'LAST_NAME_ERROR':
			return {
				...state,
				lastName: {
					...state.lastName,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}	
		case 'EMAIL':
			return {
				...state,
				email: {
					...state.email,
					value: action.payload
				}
			}
		case 'EMAIL_ERROR':
			return {
				...state,
				email: {
					...state.email,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}	
		case 'PHONE':
			return {
				...state,
				phone: {
					...state.phone,
					value: action.payload
				}
			}
		case 'PHONE_ERROR':
			return {
				...state,
				phone: {
					...state.phone,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}
		case 'PASSWORD':
			return {
				...state,
				password: {
					...state.password,
					value: action.payload
				}
			}
		case 'PASSWORD_ERROR':
			return {
				...state,
				password: {
					...state.password,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}
		case 'PASSWORD_CONFIRM':
			return {
				...state,
				passwordConfirm: {
					...state.passwordConfirm,
					value: action.payload
				}
			}
		case 'PASSWORD_CONFIRM_ERROR':
			return {
				...state,
				passwordConfirm: {
					...state.passwordConfirm,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}	
		case 'SUBJECT':
			return {
				...state,
				subject: {
					...state.subject,
					value: action.payload
				}
			}
		case 'SUBJECT_ERROR':
			return {
				...state,
				subject: {
					...state.subject,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}	
		case 'MSG':
			return {
				...state,
				msg: {
					...state.msg,
					value: action.payload
				}
			}
		case 'MSG_ERROR':
			return {
				...state,
				msg: {
					...state.msg,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}	
		case 'CHECKBOX':
			return {
				...state,
				checkBox: {
					...state.checkBox,
					value: action.payload
				}
			}
		case 'CHECKBOX_ERROR':
			return {
				...state,
				checkBox: {
					...state.checkBox,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}
		case 'PRIVACY_POLICY':
			return {
				...state,
				privacyPolicy: {
					...state.privacyPolicy,
					value: action.payload
				}
			}
		case 'PRIVACY_POLICY_ERROR':
			return {
				...state,
				privacyPolicy: {
					...state.privacyPolicy,
					error: action.payload.error,
					errorMsg: action.payload.errorMsg
				}
			}
		case 'TESTE': 
			return {
				...state,
				[action.bla]: action.payload
			}
		case 'INITIAL_STATE':
			return action.payload
			
		default:
			return state;
	}

}