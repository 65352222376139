import React, {useEffect} from 'react';
import Banner from './Banner';
import TopBanner from '../assets/img/main-image.jpg';
import ArtLogo from '../assets/img/art-logo.png';
import ArtLogoMobile from '../assets/img/art-logo-mobile.png';
import Logo from '../assets/img/logo.png';
import {RiArrowGoBackLine} from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import '../assets/css/PrivacyPolicy.css';

const PrivacyPolicy = ({mobile}) => {

	useEffect(() => {
		document.title = 'Política de Privacidade | Lovely Flowers';
		if(!mobile) {
			document.body.style.overflow = 'unset';
		}
	},[mobile]);

	const history = useHistory();

	return (

		<React.Fragment>
		<Banner
			bannerImage={TopBanner}
			mobile={mobile}
		/>

		<div className="logo-first-container">

					{
						!mobile ? 

						<img src={ArtLogo} alt="Layout" />

						:

						<img src={ArtLogoMobile} alt="Layout" />

					}

					<div className="logo-container">

						<img className="pointer" onClick={() => history.push('/')} src={Logo} alt="Logo"/>

					</div>

				</div>

		<section className="banner-next-container-fix both-side-padding">

			<div className="default-title-container">

				<h1>POLÍTICA DE PRIVACIDADE</h1>

				<p>Ultima Atualização a 27/04/2020</p>

			</div>

		</section>

		<section className="privacy-policy-container both-side-padding">

			<div className="back-privacy-policy-container">

				<div className="back-container" onClick={() => history.push('/')}>
					<RiArrowGoBackLine className="back-icon"/>
					<p>Voltar</p>
				</div>

			</div>

			<div className="privacy-policy-item-container">

				<h2>Introdução</h2>

				<p>Esta política de privacidade será aplicada a todos os dados pessoais recolhidos através do website da Lovely Flowers.</p>

			</div>

			<div className="privacy-policy-item-container">

				<h2>Responsável pelo Tratamento de Dados</h2>

				<p>A Lovely Flowers é a responsável pelo tratamento dos dados pessoais recolhidos e por assegurar a proteção da privacidade dos mesmos. 
				Para qualquer assunto deverá utilizar o e-mail <a href="mailto:geral@lovelyflowers.pt">geral@lovelyflowers.pt</a>.</p>

			</div>

			<div className="privacy-policy-item-container">

				<h2>Finalidades do Tratamento</h2>

				<p>O website da Lovely Flowers, poderá ser visitado sem ser necessário fornecer qualquer informação pessoal. 
				No entanto em algumas áreas poderá ser necessário recolher dados pessoais como, nome, email, telefone, 
				entre outros, mas apenas e após ter obtido o seu consentimento, sendo tratados em conformidade com a legislação. 
				Os dados pessoais recolhidos destinam-se a ações de comunicação, comerciais, informação e marketing da Lovely Flowers. </p>

			</div>

			<div className="privacy-policy-item-container">

				<h2>Prazo de Conservação</h2>

				<p>A Lovely Flowers recolhe e trata os dados pelo período necessário aos respetivos fins (de acordo com as <b>Finalidades do Tratamento</b>).</p>

			</div>

			<div className="privacy-policy-item-container">

				<h2>Direitos dos Utilizadores</h2>

				<p>O titular dos dados pessoais recolhidos poderá solicitar acesso aos mesmos, a todo o tempo, 
				bem como, solicitar alterações, correções, ou mesmo opor-se ao seu uso total ou parcial da respetiva informação. 
				Deverá utilizar o e-mail <a href="mailto:geral@lovelyflowers.pt">geral@lovelyflowers.pt</a> para dar a conhecer o pretendido. </p>

			</div>

			<div className="privacy-policy-item-container">

				<h2>Transmissão de Dados</h2>

				<p>A Lovely Flowers não irá transmitir os seus dados pessoais a terceiros, 
				exceto no âmbito de investigações, inquéritos e processos 
				judiciais e/ou administrativos ou de natureza semelhante, 
				desde que para tal seja devidamente ordenada por ordem judicial nesse sentido.</p>

			</div>

			<div className="privacy-policy-item-container">

				<h2>Cookies</h2>

				<p>"Cookies" são pequenos pedaços de informações que podem ajudar a identificar o seu browser, 
				e que podem armazenar informações, por exemplo, configurações e preferências.</p>

				<p>Poderão ser armazenados “cookies” no seu dispositivo para personalizar e facilitar ao máximo a sua navegação.</p>

				<p>A Lovely Flowers utiliza o serviço Google Analytics.</p>

				<p>Poderá bloquear a criação de cookies através das definições do seu navegador. No entanto, se bloquear todos os cookies, poderá não conseguir aceder a algumas funcionalidades. Estas modificações são normalmente realizadas na secção de “Opções” ou “Preferências do Navegador”.</p>

			</div>

			<div className="privacy-policy-item-container">

				<h2>Alterações à política da privacidade</h2>

				<p>A Lovely Flowers reserva-se no direito de reajustar ou alterar a presente 
				Política de Privacidade, a qualquer momento, sendo essas alterações publicadas  
				nesta página, como tal, aconselhamos a sua consulta com regularidade.</p>


			</div>

		</section>

		<section className="privacy-policy-footer both-side-padding">

			<p><Link to="/">Home</Link></p>
			<div className="signature-dots">.</div>
			<p>© 2020 Lovely Flowers</p>
			<div className="signature-dots">.</div>
			<p>Rua das Açucenas, 1300-003 Lisboa</p>
			<div className="signature-dots">.</div>
			<p><a href="mailto:geral@lovelyflowers.pt">geral@lovelyflowers.pt</a></p>

		</section>

		</React.Fragment>

	);

}

export default PrivacyPolicy;