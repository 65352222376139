import React, {useEffect, useState, useRef} from 'react';
import Home from './Home';
import Modal from './Modal';
import PrivacyPolicy from './PrivacyPolicy';
import ScrollTop from './ScrollTop';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import '../assets/css/App.css';
import '../assets/css/MediaQueries.css';


const App = () => {

	//MESURE
	const resizeTimer = useRef(null);

	const [windowResizeWidth, setWindowResizeWidth] = useState(0);
	const [windowResizeHeight, setWindowResizeHeight] = useState(0);

	useEffect(
		() => {
			const handleResize = () => {
				clearTimeout(resizeTimer.current);
				resizeTimer.current = setTimeout(() => {
					setWindowResizeWidth(window.innerWidth); 
					setWindowResizeHeight(window.innerHeight);
				}, 250);
			}
			window.addEventListener('resize', handleResize);
			setWindowResizeWidth(window.innerWidth); 
			setWindowResizeHeight(window.innerHeight);
		}, []
	);
	//MESURE

	const [modal, setModal] = useState(false);
	const [view, setView] = useState('home');
	const [mobile, setMobile] = useState(false);

	useEffect(() => {

		if (window.matchMedia("(max-width: 1150px)").matches) {
			setMobile(true);
		}else {
			setMobile(false);
		}

	},[windowResizeWidth, windowResizeHeight]);

	useEffect(() => {

		window.scrollTo(0, 0);

	},[view]);

	const handleView = () => {
		switch (view) {
			case 'home':
				return(
					<Home 
						windowResizeWidth={windowResizeWidth}
						windowResizeHeight={windowResizeHeight}
						modal={modal}
						setModal={setModal}
						setView={setView}
						mobile={mobile}
					/>
				);
			case 'modal': 
				return(
					<Modal
						modal={modal}
						setModal={setModal} 
						setView={setView}
						mobile={mobile}
					/>
				);
			default:
				return(
					<Home 
						windowResizeWidth={windowResizeWidth}
						windowResizeHeight={windowResizeHeight}
						modal={modal}
						setModal={setModal}
						setView={setView}
						mobile={mobile}
					/>
				);
		}
	}

	return (
		<Router>
			<ScrollTop/>
			<Switch>
				<Route exact path="/">
					{handleView()}
				</Route>
				<Route exact path="/privacy-policy">
					<PrivacyPolicy mobile={mobile}/>
				</Route>
			</Switch>
		</Router>
	);
	
}

export default App;