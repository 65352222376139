import React, {useReducer, useState} from 'react';
import {formReducer} from '../reducers/form';
import { Link } from 'react-router-dom';
import CloseIcon from '../assets/img/close-icon.png';
import LoaderIcon from '../assets/img/loader-icon.png';
import '../assets/css/Modal.css';

const Modal = ({modal, setModal, setView, mobile}) => {

	const [loader, setLoader] = useState(false);
	const [successMsg, setSuccessMsg] = useState(false);
	const [errorSend, setErrorSend] = useState(false);

	const initialState = {
		name: {
			value: '',
			error: null,
			errorMsg: ''
		},
		email: {
			value: '',
			error: null,
			errorMsg: ''
		},
		phone: {
			value: '',
			error: null,
			errorMsg: ''
		},
		msg: {
			value: '',
			error: null,
			errorMsg: ''
		},
		privacyPolicy: {
			value: false,
			error: null,
			errorMsg: ''
		}
	}

	const [state, dispatch] = useReducer(formReducer, initialState);

	const validateForm = () => {

		let errorName = true;
		let errorEmail = true;
		let errorPhone = true;
		let errorMsg = true;
		let errorPrivacyPolicy = true;

		if(!state.name.value && !state.name.value.trim().length > 0) {
			dispatch({type: 'NAME_ERROR', payload: {error: true, errorMsg: 'Campo de preenchimento obrigatório.'}});
			errorName = true;
		}else if(state.name.value.trim().length < 3) {
			dispatch({type: 'NAME_ERROR', payload: {error: true, errorMsg: 'Deve conter pelo menos 3 caracteres.'}});
			errorName = true;
		}else {
			dispatch({type: 'NAME_ERROR', payload: {error: false, errorMsg: ''}});
			errorName = false;
		}

		if(!state.email.value && !state.email.value.trim().length > 0) {
			dispatch({type: 'EMAIL_ERROR', payload: {error: true, errorMsg: 'Campo de preenchimento obrigatório.'}});
			errorEmail = true;
		}else if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email.value.trim())) {
			dispatch({type: 'EMAIL_ERROR', payload: {error: true, errorMsg: 'Formato de email inválido.'}});
			errorEmail = true;
		}else {
			dispatch({type: 'EMAIL_ERROR', payload: {error: false, errorMsg: ''}});
			errorEmail = false;
		}

		if(!state.phone.value && !state.phone.value.trim().length > 0) {
			dispatch({type: 'PHONE_ERROR', payload: {error: true, errorMsg: 'Campo de preenchimento obrigatório.'}});
			errorPhone = true;
		}else if(state.phone.value.trim().length > 0 && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(state.phone.value.trim())) {
			dispatch({type: 'PHONE_ERROR', payload: {error: true, errorMsg: 'Formato de telefone inválido.'}});
			errorPhone = true;
		}else {
			dispatch({type: 'PHONE_ERROR', payload: {error: false, errorMsg: ''}});
			errorPhone = false;
		}

		if(!state.msg.value && !state.msg.value.trim().length > 0) {
			dispatch({type: 'MSG_ERROR', payload: {error: true, errorMsg: 'Campo de preenchimento obrigatório.'}});
			errorMsg = true;
		}else {
			dispatch({type: 'MSG_ERROR', payload: {error: false, errorMsg: ''}});
			errorMsg = false;
		}

		if(!state.privacyPolicy.value) {
			dispatch({type: 'PRIVACY_POLICY_ERROR', payload: {error: true, errorMsg: 'Campo de preenchimento obrigatório.'}});
			errorPrivacyPolicy = true
		}else {
			dispatch({type: 'PRIVACY_POLICY_ERROR', payload: {error: false, errorMsg: ''}});
			errorPrivacyPolicy = false;
		}

		if(errorName || errorEmail || errorPhone || errorMsg || errorPrivacyPolicy) {
			return false;
		}else {
			return true;
		}

	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		if(validateForm()) {

			setLoader(true);

			const formData = new FormData();
			formData.append('name', state.name.value);
	    	formData.append('email', state.email.value);
	    	formData.append('phone', state.phone.value);
	    	formData.append('msg', state.msg.value);

	    	const response = await fetch('https://lovelyflowers.pt/api/requests/form.php', {
				method: 'post',
	    		body: formData
	    	});

			const data = await response.json();

			if(data === true) {
				dispatch({type: 'INITIAL_STATE', payload: initialState});
				setLoader(false);
				setSuccessMsg(true);
			}else {
				setLoader(false);
				setErrorSend(true);
				setSuccessMsg(true);
			}

		}

	}

	const handleClick = (e) => {

		if(e.target.id === 'modal-background' && loader === false) {
			setModal(false);
		}
	}

	const handleMobileModalClose = () => {
		setModal(false);
		setView('home');
	}

	const handleMobileModalSuccess = () => {

		if(!mobile) {
			setModal(false);
			setSuccessMsg(false);
			setErrorSend(false);
		}else {
			setModal(false);
			setSuccessMsg(false);
			setErrorSend(false);
			setView('home');
		}
	}

	return (

		<section id="modal-background" className={`modal-background-container ${modal ? 'active' : ''}`} onMouseDown={(e) => handleClick(e)}>


			{loader ? 

				<div className="loader-container">

					<img className="loader" src={LoaderIcon} alt="Loader"/> 

				</div>
					
			: false}

			{successMsg ? 

				<div className="success-msg-background-container" onClick={() => handleMobileModalSuccess()}>

						<div className="success-msg-container">

							{!errorSend 

							?

								<React.Fragment>
									<h3>A sua mensagem foi<br/>
									entregue com sucesso!</h3>

									<p>Entraremos em contacto consigo assim que possível.</p>
								</React.Fragment>

							:
								<React.Fragment>
									<h3>O envio falhou.<br/>
									Por favor, tente novamente.</h3>
								</React.Fragment>
							}

					</div>

				</div>	

			:false }

			<div className={`modal-container ${!mobile ? modal && !loader && !successMsg ? 'active' : '' : 'active'}`}>

				<div className="close-container">

					<img src={CloseIcon} onClick={() => !mobile ? setModal(false) : handleMobileModalClose()} alt="Close"/>

				</div>

				<div className="title-container">

					<h3>O que podemos fazer por si?</h3>

				</div>

				<form className="register-form-container form-container" onSubmit={handleSubmit}>

					<div className="form-padding">

						<div className="input-container">

							<div className="input-div">

								<input 
									className={state.name.error === true ? 'error-active' : ''} 
									type="text" 
									placeholder="Nome*" 
									onChange={(e) => {
										dispatch({type: 'NAME', payload: e.target.value});
										dispatch({type: 'NAME_ERROR', payload: {error: null, errorMsg: ''}});
									}}
									value={state.name.value}
								/>

							</div>

							{state.name.error === true ? 

								<div className={`${!mobile ? 'input-error show' :  'input-error-container'}`}>
									
									<p>{state.name.errorMsg}</p> 

								</div>

							: false}

						</div>

						<div className="input-container">

							<div className="input-div">

								<input 
									className={state.email.error === true ? 'error-active' : ''} 
									type="text" 
									placeholder="E-mail*" 
									onChange={(e) => {
										dispatch({type: 'EMAIL', payload: e.target.value});
										dispatch({type: 'EMAIL_ERROR', payload: {error: null, errorMsg: ''}});
									}}
									value={state.email.value}
								/>

							</div>

							{state.email.error === true ? 

								<div className={`${!mobile ? 'input-error show' :  'input-error-container'}`}>
									
									<p>{state.email.errorMsg}</p> 

								</div>

							: false}

						</div>

						<div className="input-container">

							<div className="input-div">

								<input 
									className={state.phone.error === true ? 'error-active' : ''} 
									type="text" 
									placeholder="Telefone*" 
									onChange={(e) => {
										dispatch({type: 'PHONE', payload: e.target.value});
										dispatch({type: 'PHONE_ERROR', payload: {error: null, errorMsg: ''}});
									}}
									value={state.phone.value}
								/>

							</div>

							{state.phone.error === true ? 

								<div className={`${!mobile ? 'input-error show' :  'input-error-container'}`}>
									
									<p>{state.phone.errorMsg}</p> 

								</div>

							: false}

						</div>

						<div className="input-container">

							<div className="input-div">

								<textarea 
									className={state.msg.error === true ? 'error-active' : ''} 
									placeholder="Mensagem*" 
									onChange={(e) => {
										dispatch({type: 'MSG', payload: e.target.value});
										dispatch({type: 'MSG_ERROR', payload: {error: null, errorMsg: ''}});
									}}
									value={state.msg.value}
								/>

							</div>

							{state.msg.error === true ? 

								<div className={`${!mobile ? 'input-error show' :  'input-error-container'}`}>
									
									<p>{state.msg.errorMsg}</p> 

								</div>

							: false}

						</div>

						<div className="input-container checkbox-full-container">
							<label className="checkbox-container">
								<input 
									className="checkbox" 
									type="checkbox" 
									checked={state.privacyPolicy.value} 
									onChange={(e) => {
										dispatch({type: 'PRIVACY_POLICY', payload: e.target.checked});
										dispatch({type: 'PRIVACY_POLICY_ERROR', payload: {error: false, errorMsg: ''}});
									}}
								/>
								<div className="custom-checkbox">
									<span className={`${state.privacyPolicy.error === true ? 'error-active' : ''} new-container`}></span>
								</div>
							</label>
							<p className="text">Li e concordo com a <Link to="privacy-policy">Política de Privacidade</Link>.</p>

							{state.privacyPolicy.error === true && !mobile ? 

							<div className="input-error bottom-space show">
									
								<p>{state.privacyPolicy.errorMsg}</p> 

							</div>

							: false}

						</div>

							{state.privacyPolicy.error === true && mobile ? 

							<div className="input-error-container">
									
								<p>{state.privacyPolicy.errorMsg}</p> 

							</div>

							: false}

						

					</div>

						<div className="submit-container">

							<div className="gray-art"></div>

							<input type="submit" value="ENVIAR"/> 

						</div>

				</form>

			</div>

		</section>

	);

}

export default Modal;